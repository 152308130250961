class ProductListedModule {
  static showSizeVariant(baseProductId, sizeGroupId, targetProductId) {
    const elementGroupClass = `product-cell--sizes-${baseProductId}-${sizeGroupId}`;

    document.querySelectorAll(`.${elementGroupClass}`).forEach((groupElement) => {
      groupElement.classList.add("product-cell--hidden");
    });

    const selectors = `.${elementGroupClass}.product-cell--${targetProductId}`;
    const element = document.querySelector(selectors);
    if (element) {
      element.classList.remove("product-cell--hidden");
    }
  }

  static showWeightVariant(baseProductId, weightGroupId, targetProductId) {
    const elementGroupClass = `product-cell--weights-${baseProductId}-${weightGroupId}`;

    document.querySelectorAll(`.${elementGroupClass}`).forEach((groupElement) => {
      groupElement.classList.add("product-cell--hidden");
    });

    const selectors = `.${elementGroupClass}.product-cell--${targetProductId}`;
    const element = document.querySelector(selectors);
    if (element) {
      element.classList.remove("product-cell--hidden");
    }
  }

  static showColorVariant(baseProductId, colorGroupId, targetProductId) {
    const elementGroupClass = `product-cell--colors-${baseProductId}-${colorGroupId}`;

    document.querySelectorAll(`.${elementGroupClass}`).forEach((groupElement) => {
      groupElement.classList.add("product-cell--hidden");
    });

    const selectors = `.${elementGroupClass}.product-cell--${targetProductId}`;
    const element = document.querySelector(selectors);
    if (element) {
      element.classList.remove("product-cell--hidden");
    }
  }
}

export default ProductListedModule;
